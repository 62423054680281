/*TODO: 
-switch work section layout to either one of the following options:
-> minimize the non-recent work in size (bot positions); maximize most recent work (top position).
  User can click on any work stack to maximize it. Only 1 work stack can be maximized at once.
-> to an infinite slider (only if there are at least 4 jobs): 
https://verpex.com/blog/website-tips/how-to-create-an-infinite-image-slider-using-css

-Projects inner responsiveness (aspect ratio and %)

Possible adds:
-switch tab icon?
-make projects slider slide upon finger slide prompt for mobile?
-add multiple res of each img and load appropriate one?
-add typing animation in block--hero section, below its content?
*/

:root {
  --color-nav-item: rgb(220, 220, 220);
  --color-banner-title: linear-gradient(
    271deg,
    #a0e9ff 5%,
    #a162e8 20%,
    #f093b0 50%,
    #edca85 75%
  );
  --color-default: #fff;

  --backG-shape: polygon(0 0, 60% 0, 100% 40%, 100% 100%, 40% 100%, 0 60%);
  --backG-color: #111;

  --prj1-rgb: #ff0077;
  --prj1-rgba: #ff007766;

  --prj2-rgb: #0033ff;
  --prj2-rgba: #0033ff66;

  --prj3-rgb: #00e33d;
  --prj3-rgba: #00e33d66;

  --prj4-rgb: #ff4e00;
  --prj4-rgba: #ff4e0066;

  --prj5-rgb: #8000ff;
  --prj5-rgba: #8000ff66;
}

/* Typography */

::selection {
  background-color: dodgerblue;
  -webkit-text-fill-color: var(--color-default);
  color: var(--color-default);
}

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: var(--color-default);
  line-height: 1.5;
  font-size: 2rem;
}

h1,
h2,
h3 {
  color: var(--color-headings);
  margin-bottom: 1rem;
  line-height: 1.1;
}

h1 {
  /* 7 x font size of the html element */
  font-size: 7rem;
}

h2 {
  margin-block: 0;
  font-weight: 400;
  font-size: 4rem;
}

h3 {
  font-size: 3rem;
  font-weight: 500;
}

p {
  margin-top: 0;
}

.italic {
  font-style: italic;
}

.link--cyan {
  color: cyan;
  text-decoration: none;
}

@media screen and (min-width: 1024px) {
  body {
    font-size: 1.8rem;
  }

  h1 {
    font-size: 8rem;
  }

  h2 {
    font-size: 4rem;
  }

  h3 {
    font-size: 2.4rem;
  }
}

.disabled {
  pointer-events: none;
  cursor: default;
}

/* Flex */

.flex {
  display: flex;
}

/* icons */

.icon {
  display: inline-block;
}

.icon--social {
  cursor: pointer;
  width: 2rem;
  margin-right: 1rem;
  transition: all 0.3s;
}

.icon--social:hover {
  transform: scale(1.3);
}

.icon--social:last-of-type {
  margin-right: 0;
}

.icon--social:hover #mail {
  fill: var(--color-default);
}

.icon--social:hover #ig {
  fill: #d1598d;
}

.icon--social:hover #linkedIn {
  fill: #2687bb;
}

.icon--work {
  width: 10rem;
  height: 10rem;
  fill: red;
  transition: all 0.3s;
}

/* nav */

nav {
  background-color: rgba(17, 17, 17, 0.9);
  width: 100vw;
  position: fixed;
  z-index: 101;
  backdrop-filter: blur(0.5rem);
  -webkit-backdrop-filter: blur(0.5rem);
}

.navbar {
  margin: auto;
  padding: 0;
  max-width: 160rem;
  justify-content: space-around;
  list-style-type: none;
  font-size: 1.7rem;
}

.nav--mobile {
  justify-content: space-between;
}

.nav__item {
  display: inline-block;
  padding: 1rem;
  color: var(--color-default);
}

#burger {
  display: none;
  position: relative;
  cursor: pointer;
}

.bun {
  position: absolute;
  transition: all 0.33s;
}

.bun:nth-of-type(1) {
  transform: translateY(-30%);
}
.bun:nth-of-type(3) {
  transform: translateY(30%);
}

#signature {
  position: relative;
}

#signature a {
  display: inline-block;
  font-family: "Lora", serif;
  font-size: larger;
  color: var(--color-default);
  text-decoration: none;
}

#signature a::selection {
  background-color: yellow;
  -webkit-text-fill-color: black;
  color: black;
}

#signature a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -25%;
  width: 100%;
  height: 0.2rem;
  background-color: rgb(253, 124, 101);
  border-radius: 0.4rem;
  scale: 0 1;
  transform-origin: left;
  transition: scale 0.2s;
}

#signature a:hover::before {
  scale: 1;
}

.nav__link {
  cursor: pointer;
  text-decoration: none;
  padding: inherit;
  color: var(--color-nav-item);
  transition: all 0.2s;
}

.nav__link:hover {
  color: var(--color-default);
  text-shadow: 0 0 1rem var(--color-default);
}

.contact {
  margin-top: 0.2rem;
  display: block;
}

.nav__svg {
  fill: var(--color-nav-item);
  transition: all 0.3s;
}

@media screen and (max-width: 650px) {
  nav {
    overflow: hidden;
    max-height: 5rem;
  }

  .navbar {
    flex-wrap: wrap;
    text-align: center;
  }

  .nav--expanded {
    left: 0;
    max-width: 100%;
    max-height: 100%;
    transition: all 1s;
  }

  .nav__item {
    flex-basis: 100%;
  }

  .nav--mobile {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.1rem solid rgba(211, 211, 211, 0.5);
  }

  #signature {
    margin-left: 1.5rem;
  }

  #burger {
    display: inline;
    margin-right: 2.5rem;
    padding-bottom: 0.5rem;
  }

  .animate-burger {
    padding-left: 1rem;
    padding-bottom: 10rem;
  }

  .animate-burger .bun:nth-of-type(1) {
    animation: top-bun 0.66s;
    animation-fill-mode: forwards;
  }

  .animate-burger .bun:nth-of-type(2) {
    opacity: 0;
  }

  .animate-burger .bun:nth-of-type(3) {
    animation: bottom-bun 0.66s;
    animation-fill-mode: forwards;
  }
}

@keyframes top-bun {
  50% {
    transform: translateY(0);
  }
  100% {
    transform: rotate(-45deg);
  }
}

@keyframes bottom-bun {
  50% {
    transform: translateY(0);
  }
  100% {
    transform: rotate(45deg);
  }
}

/* BackG */

.backG {
  width: 100vw;
  height: 100vh; /* backup */
  height: 100lvh; /* adjusts for largest UI height e.g. no search bar */
  position: fixed;
  z-index: -10;
  animation: changeBg 5s infinite alternate linear;
}

@keyframes changeBg {
  0% {
    background: #ee6055;
  }
  25% {
    background: #60d394;
  }
  50% {
    background: #aaf683;
  }
  75% {
    background: #ffd97d;
  }
  100% {
    background: #ff9b85;
  }
}

.backG__stripe {
  width: 100%;
  height: 100%;
  clip-path: var(--backG-shape);
  background-color: var(--backG-color);
}

/* ForeG */

.foreG {
  overflow-x: hidden;
}

/* Blocks */

.block {
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 8.5rem;
}

.block--hero {
  height: min-content;
  align-items: center;
  margin-top: 10rem;
  margin-bottom: 11.5rem;
}

.block--prj {
  margin-bottom: 0;
}

@media screen and (max-width: 900px) {
  .block {
    margin-top: 8rem;
  }

  .block--hero {
    height: min-content;
    margin-bottom: 2rem;
  }
}

/* Me */

.me {
  position: relative;
  text-align: center;
  flex-basis: clamp(29rem, 40%, 50rem);
  grid-template-rows: min-content min-content;
  row-gap: 7.5%;
}

@media screen and (max-width: 900px) {
  .me {
    flex-basis: clamp(27rem, 80%, 65rem);
  }
}

/* Boxes */

.box {
  border-radius: 2.5rem;
  transition: all 0.3s;
  backdrop-filter: blur(2rem);
  -webkit-backdrop-filter: blur(2rem);
}

.box--me {
  box-shadow: 0 0 2rem 0.5rem #f3f3f3;
  padding: 4rem;
  backdrop-filter: blur(1rem);
  -webkit-backdrop-filter: blur(1rem);
  perspective: 60rem;
  cursor: pointer;
}

.rotateY3D {
  animation: rotateY3D 1s;
}

.box--work {
  border: 0.1rem solid lightgray;
  padding: 0 4rem;
  min-width: min-content;
  width: 69%;
  max-width: 105.5rem;
  justify-content: center;
  flex-wrap: wrap;
}

.box--prj {
  width: 69%;
  height: min-content;
  max-width: 105.5rem;
  position: relative;
  border: 0.1rem solid lightgray;
  overflow-x: hidden;
  transition: box-shadow 0.3s !important;
}

.box--work:hover,
.box--prj:hover {
  box-shadow: 0 0 1rem 0.1rem #f3f3f3;
}

.box__title {
  flex-basis: 100%;
  text-align: center;
  margin-bottom: 5rem;
}

.box--prj .box__title {
  width: 100%;
  margin-bottom: 3rem;
  text-align: center;
}

@media screen and (max-width: 850px) {
  .box__title {
    margin-bottom: 0;
  }

  .box--work {
    padding-inline: 0;
    padding-bottom: 5rem;
    width: 85%;
  }

  .box--prj {
    width: 85%;
  }
}

@media screen and (max-width: 355px) {
  .box--work,
  .box--prj {
    max-width: min-content;
    padding: 0 1rem;
  }
}

@keyframes rotateY3D {
  100% {
    transform: rotateY(360deg);
  }
}

#me,
#work,
#projects {
  scroll-margin-top: 10rem;
}

/* Fronts */

.front {
  display: block;
}

.fadeOut {
  animation: fadeOut 0.5s;
  animation-fill-mode: forwards;
}

.front__title {
  font-size: 6.5rem;
  line-height: 1.12;
  margin-bottom: 3.5rem;
  background: var(--color-banner-title);
  background-size: 300%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  animation: bannerText 5s infinite ease-in-out;
}

.title__intro {
  font-size: 6.5rem;
  font-weight: 500;
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
}

.title__occupation {
  display: block;
  font-size: 3rem;
  line-height: 1.3;
}

.front__synopsis {
  font-size: 2.2rem;
}

@keyframes bannerText {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Backs */

.back {
  display: none;
  font-size: 2rem;
  line-height: 1.55;
}

.fadeIn {
  animation: fadeIn 0.5s;
  animation-fill-mode: forwards;
}

.location {
  color: greenyellow;
}

.university {
  color: rgb(255, 130, 108);
}

.languages {
  color: rgb(252, 206, 121);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Buttons */

.btn {
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: 600;
  white-space: nowrap;
  text-align: center;
  transition: all 0.3s ease;
  border: none;
  padding: 1rem 3rem;
  border-radius: 2.5rem;
  outline: none;
  position: relative;
}

.btn:not(.btn--CV):hover {
  transform: scale(1.07);
}

.btn:not(.btn--CV):active {
  opacity: 0.8;
}

.btn a,
a .btn {
  color: var(--color-default);
  text-decoration: none;
}

.download-btn {
  margin: auto;
  width: min-content;
  height: min-content;
}

.download-btn:hover button {
  color: black;
}

.btn--CV {
  width: min-content;
  margin: auto;
  background-color: transparent;
  border-radius: 5rem;
  border: 0.3rem solid greenyellow;
  padding: 0.7rem 3rem 1.3rem 2.5rem;
}

.btn__svg {
  width: 3rem;
  transform: translateY(0.5rem);
  stroke: var(--color-default);
}

.btn--CV::before,
.btn--CV::after {
  content: "";
  opacity: 0.3;
  position: absolute;
  width: 4rem;
  height: 4rem;
  border-radius: inherit;
  border: inherit;
  transition: opacity 0.1s, width 0.25s 0.15s, height 0.25s;
}

.btn--CV::before {
  top: -1.3rem;
  left: -1.3rem;
  border-width: 0.3rem 0 0 0.3rem;
}

.btn--CV::after {
  bottom: -1.3rem;
  right: -1.3rem;
  border-width: 0 0.3rem 0.3rem 0;
}

.btn--CV:hover::before,
.btn--CV:hover::after {
  opacity: 1;
  width: calc(100% + 2.5rem);
  height: calc(100% + 2.5rem);
}

.btn--CV:hover {
  background-color: greenyellow;
}

.btn--CV:hover .btn__svg {
  stroke: #222;
}

.btn--CV:active {
  background-color: rgb(230, 255, 193);
}

.btn--pink {
  background-color: var(--prj1-rgb);
}

.btn--blue {
  background-color: var(--prj2-rgb);
}

.btn--green {
  background-color: var(--prj3-rgb);
}

.btn--orange {
  background-color: var(--prj4-rgb);
}

.btn--purple {
  background-color: var(--prj5-rgb);
}

/* Flag */

.flag {
  margin-left: 10%;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .flag {
    margin-left: 5%;
    flex-basis: 100%;
  }
}

/* Grid */

.grid {
  display: grid;
}

.grid--centered {
  display: grid;
  place-items: center;
}

/* Mosaic */

.mosaic {
  position: relative;
  grid-template-rows: repeat(3, min-content);
  grid-template-columns: repeat(2, min-content);
  row-gap: 10%;
  column-gap: 20%;
}

.mosaic__img {
  position: relative;
  /* width: 16rem;
  height: 9rem; */
  width: 20rem;
  height: 11.25rem;
  border-radius: 1rem;
  border: 0.15rem solid whitesmoke;
  object-fit: cover;
  transition: all 0.3s;
  animation: floatY 5s infinite alternate ease-in-out;
}

.mosaic__img:nth-of-type(2) {
  animation-delay: -0.83s;
}
.mosaic__img:nth-of-type(3) {
  animation-delay: -1.66s;
}
.mosaic__img:nth-of-type(4) {
  animation-delay: -2.5s;
}

.mosaic__img:nth-child(even) {
  left: -3rem;
  top: 4rem;
}

.mosaic__img:nth-child(5) {
  left: 10rem;
  top: -17rem;
}

@keyframes floatY {
  0% {
    transform: translate(0, 3rem);
  }
}
@media screen and (max-width: 1202px) {
  .mosaic__img {
    width: 16rem;
    height: 9rem;
  }
}

@media screen and (max-width: 900px) {
  .mosaic {
    column-gap: 3rem;
    margin-top: 10%;
  }

  .mosaic__img {
    width: 20rem;
    height: 11.25rem;
  }

  .mosaic__img:last-of-type {
    top: -11rem;
    left: 7rem;
  }
}

@media screen and (max-width: 570px) {
  .mosaic__img {
    margin-top: 15%;
    width: 16rem;
    height: 9rem;
  }
}

@media screen and (max-width: 355px) {
  .mosaic__img {
    width: 12rem;
    height: 6.75rem;
  }
}

/* Stacks & Cards */

.stacks {
  grid-template-rows: repeat(3, min-content);
}

.cards {
  position: relative;
  cursor: pointer;
  margin-bottom: 8rem;
  margin-inline: 1rem;
}

.card {
  width: 55rem;
  height: 37rem;
  position: relative;
  border-radius: 1rem;
  justify-content: center;
}

.card p:last-of-type {
  margin-bottom: 0;
}

.cards::before {
  position: absolute;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  color: var(--color-default);
  top: 0.5rem;
  left: 1rem;
  z-index: 2;
  opacity: 0.9;
}

#cards--0::before {
  content: "2023-2024";
  font-weight: bold;
}

#cards--1::before {
  content: "2022";
  font-weight: bold;
}

#cards--2::before {
  content: "2021-2022";
  font-weight: bold;
}

.card--head {
  z-index: 1;
  background-color: #333;
  box-shadow: 0 0 1rem 0.25rem grey;
  align-items: center;
  flex-wrap: wrap;
  transition: all 0.3s;
  font-family: "Gemunu Libre", sans-serif;
  font-size: 4rem;
  color: dodgerblue;
  font-style: italic;
  font-weight: 600;
}

.card--tail {
  position: absolute;
  top: 0;
  padding: 1rem;
  overflow: auto;
  background: rgba(26, 26, 121, 0.9);
  border: 0.3rem solid rgb(207, 180, 234);
  text-align: center;
  transition: all 0.4s;
}

.sized {
  width: 30%;
  object-fit: contain;
  border-radius: 1rem;
}

.tail__title {
  display: block;
  font-weight: bold;
  color: rgb(255, 255, 88);
}

.shuffle .card--head {
  box-shadow: inset 0 0 6rem whitesmoke, inset 2rem 0 8rem #f0f,
    inset 2rem 0 8rem #0ff, inset 2rem 0 30rem #f0f, inset -2rem 0 30rem #0ff,
    0 0 5rem var(--color-default), -1rem 0 8rem #f0f, 1rem 0 8rem #0ff;
}

.shuffle .card--tail {
  animation: shuffle 1.1s ease-in-out;
  animation-fill-mode: forwards;
}

.reverse-shuffle .card--head {
  box-shadow: 0 0 2rem 0.5rem grey;
}

.reverse-shuffle .card--tail {
  animation: reverse-shuffle 1.1s ease-in-out;
  animation-fill-mode: forwards;
  z-index: 3;
}

@keyframes shuffle {
  50% {
    transform: translate(4rem, 23rem);
  }
  100% {
    transform: translateY(3rem);
    z-index: 100;
  }
}

@keyframes reverse-shuffle {
  0% {
    top: 3rem;
    transform: rotate(0);
  }
  50% {
    transform: translate(4rem, 25rem);
    z-index: 0;
  }
  100% {
    transform: translateY(2rem, 2rem);
    z-index: 0;
    transform: rotate(10deg);
  }
}

@media screen and (max-width: 850px) {
  .stacks {
    grid-template-rows: repeat(2, min-content);
    grid-template-columns: min-content;
  }

  .cards {
    margin-top: 5rem;
    margin-bottom: 10rem;
  }

  .cards:last-of-type {
    margin-bottom: 5rem;
  }
  .card {
    width: 40.5rem;
    height: 27rem;
  }
}

@media screen and (max-width: 550px) {
  .card {
    width: 33.75rem;
    height: 22.5rem;
  }
}

@media screen and (max-width: 480px) {
  .card {
    width: 27rem;
    height: 18rem;
  }
}

@media screen and (min-width: 851px) {
  .cards:hover .card--head {
    color: var(--color-default);
  }

  .cards:hover .icon--work {
    fill: var(--color-default);
  }

  .cards:hover .card--tail {
    transform: translate(2rem, 2rem);
    transform: rotate(10deg);
  }

  .cards:hover .card--head {
    box-shadow: inset 0 0 6rem whitesmoke, inset 2rem 0 8rem #f0f,
      inset 2rem 0 8rem #0ff, inset 2rem 0 30rem #f0f, inset -2rem 0 30rem #0ff,
      0 0 5rem var(--color-default), -1rem 0 8rem #f0f, 1rem 0 8rem #0ff;
  }
}

/* box--prj bg */

.bg {
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.1);
  width: 55%;
  transform: skewX(7deg);
  position: absolute;
  height: 100%;
  left: -10%;
  padding-left: 20rem;
  transform-origin: 0 100%;
}

.bg::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: inherit;
  pointer-events: none;
  transform: skewX(10deg);
}

@media screen and (max-width: 960px) {
  .bg {
    display: none;
  }
  .bg::before {
    width: 120%;
    bottom: 0;
    transform: skewX(0deg);
  }
}

/* Slider */

.slider {
  padding-block: 2.5%;
  display: flex;
  width: 500%;
  height: min-content;
  transition: all 0.25s ease-in;
  transform: translateX(0);
  align-items: center;
}

.slide {
  height: min-content;
  width: 100%;
  padding-inline: 1%;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 5%;
  align-items: center;
  overflow: hidden;
  position: relative;
}

@media screen and (max-width: 960px) {
  .slider {
    padding: 0;
  }

  .slide {
    grid-template-columns: 1fr;
    grid-template-rows: 20rem 1fr;
    padding-inline: 0;
  }
}

.details {
  padding-left: 20%;
}

.details h4 {
  margin-top: 0;
  font-size: 3rem;
  font-weight: 400;
  margin-bottom: 1rem;
  line-height: 1.25;
}

.details p {
  height: min-content;
  margin-bottom: 2rem;
}

@media screen and (max-width: 960px) {
  .details {
    text-align: center;
    padding-inline: 2rem;
  }

  .details p {
    margin-right: 0;
  }
}

/* Illustration */

.illustration {
  height: 100%;
  justify-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 960px) {
  .illustration {
    grid-row: 1 / span 1;
  }
}

.illus__inner {
  height: 75%;
  max-height: 20rem;
  aspect-ratio: 1 / 1;
  border-radius: 3rem;
  position: relative;
}

@supports not (aspect-ratio: 1 / 1) {
  .illus__inner {
    width: 18rem;
  }
}

.illus__inner img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: inherit;
  z-index: 10;
}

.illus__inner::after,
.illus__inner::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 3rem;
  top: 0;
  left: 0;
}

.illus__inner::after {
  transform: translate(4rem, -1rem);
}

.illus__inner::before {
  transform: translate(2rem, -2rem);
}

.slide--1 .illus__inner::after,
.slide--1 .illus__inner::before {
  background-color: var(--prj1-rgba);
}

.slide--2 .illus__inner::after,
.slide--2 .illus__inner::before {
  background-color: var(--prj2-rgba);
}

.slide--3 .illus__inner::after,
.slide--3 .illus__inner::before {
  background-color: var(--prj3-rgba);
}

.slide--4 .illus__inner::after,
.slide--4 .illus__inner::before {
  background-color: var(--prj4-rgba);
}

.slide--5 .illus__inner {
  background-color: var(--prj5-rgb);
  transform: skew(-10deg);
}

.slide--5 .illus__inner::after,
.slide--5 .illus__inner::before {
  background-color: var(--prj5-rgba);
}

/* Arrows */

.arrow {
  z-index: 1;
  position: absolute;
  width: 4rem;
  cursor: pointer;
  opacity: 0.2;
  transition: all 0.3s ease;
  transform: translateY(-50%);
}

.arrow:hover {
  opacity: 1;
}

.prev {
  top: 50%;
  left: 2%;
}

.next {
  top: 50%;
  right: 2%;
}

@media screen and (max-width: 440px) {
  .arrow {
    display: none;
  }
}

/* Trail */

.trail {
  z-index: 1;
  margin-inline: auto;
  width: 60%;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
  text-align: center;
  font-size: 1.5rem;
  margin-top: 2rem;
  user-select: none;
}

@media screen and (max-width: 960px) {
  .trail {
    width: 90%;
    bottom: 2%;
    margin-top: 0;
  }
}

.trail div {
  padding: 2rem;
  border-top: 0.3rem solid var(--color-default);
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.3s ease;
}

.trail div:hover {
  opacity: 0.6;
}

@media screen and (max-width: 960px) {
  .trail div {
    padding: 1rem;
  }
}

.active {
  opacity: 1 !important;
}

/* Copyright */

#copyright {
  display: block;
  text-align: center;
  font-size: 1.5rem;
  margin-block: 3rem;
}

#copyright::selection {
  background-color: transparent;
}
